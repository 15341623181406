/* Declarations */
$font-title: 'Arial';
$font-body: 'Arial';
$font-body-bold: 'Arial';
/* custom fonts*/
$font-roboto-mono: 'Roboto Mono', sans-serif;
$font-rubik-one: 'Rubik One', sans-serif;
$font-jura: 'Jura';
$font-rubik: 'Rubik';
$font-inter: 'Inter';
$font-podkova: 'Podkova';
$font-inria: 'Inria';
$font-inria-bold: 'Inria-Bold';

@font-face {
  font-family: 'Inria';
  src: url('../assets/fonts/InriaSerif-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inria-Bold';
  src: url('../assets/fonts/InriaSerif-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Jura';
  src: url('../assets/fonts/Jura.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('../assets/fonts/RobotoMono.ttf') format('truetype');
}

@font-face {
  font-family: 'Rubik One';
  src: url('../assets/fonts/RubikOne.ttf') format('truetype');
}

@font-face {
  font-family: 'Rubik';
  src: url('../assets/fonts/Rubik.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter.ttf') format('truetype');
}

@font-face {
  font-family: 'Podkova';
  src: url('../assets/fonts/Podkova.ttf') format('truetype');
}

@mixin heading1 {
  font-family: $font-inter;
  font-size: 100px;
  line-height: 105px;
  font-weight: 900;
  letter-spacing: -5px;
}

@mixin heading2 {
  font-family: $font-inter;
  font-size: 40px;
  line-height: 45px;
  font-weight: 900;
  letter-spacing: -0.8px;
}

@mixin body1 {
  font-family: $font-inria;
  letter-spacing: 4%;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
}

@mixin body2 {
  font-family: $font-inria-bold;
  letter-spacing: 0.05px;
  font-size: 20px;
  line-height: 22px;
  // font-weight: 500;
}

@mixin paragraph1 {
  font-family: $font-inria;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.5px;
}

@mixin pooks-subtitle {
  font-family: $font-roboto-mono;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.8px;
}

@mixin pooks-subtitle-bold {
  font-family: $font-roboto-mono;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 79px;
  letter-spacing: 1.12px;
}

@mixin pooks-title {
  font-family: $font-inter;
  font-style: normal;
  font-weight: 900;
  font-size: 90px;
  line-height: 79px;
  /* or 92px */
  letter-spacing: 0.9px;
}

@mixin pooks-title-small {
  @include pooks-title;
  font-size: 22px;
  line-height: 27px;
}

@mixin pooks-text {
  font-family: $font-jura;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: -0.025em;
}


h3 {
  font-family: $font-body;
  font-size: 12px;
  justify-content: center;
  font-weight: 900;
  line-height: 17px;
  letter-spacing: 0.03em;
  margin: 0px;
}

p {
  font-family: $font-body;
  font-style: normal;
  font-weight: 900;
  font-size: 8px;
  line-height: 11px;
  letter-spacing: 0.07em;
}
