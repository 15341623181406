@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/common.scss";

.expandable-component-container {
  @include center;
  flex-direction: column;
  width: 100%;
  .expand-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 0px;
    background-color: transparent;
    border: none;
    .expandable-title {
      font-family: $font-inter;
      text-transform: uppercase;
      font-weight: 700;
      color: $color-white;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.015em;
    }
    .expand-more-icon {
      color: $color-white;
      width: 16px;
      height: auto;
      transition: transform 0.3s ease;
      &.open {
        transform: rotate(180deg);
      }
    }
  }
  .collapse {
    width: 100%;
    padding-left: 20px;
    .expand-content {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 10px;
      padding-bottom: 20px;
      .expand-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .expand-item-text {
          display: flex;
          flex-direction: row;
          gap: 10px;
          align-items: center;
          justify-content: flex-start;
          font-family: $font-inria-bold;
          color: $color-white;
          font-size: 16px;
          line-height: 25px;
          .expandable-component-socials-icon {
            &.MuiSvgIcon-root {
              color: $color-white;
              width: 21px;
              height: 21px;
            }
          }
        }
      }
    }
  }
}
