@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/common.scss";

.hamburger-menu-container {
  @include center;
  width: 100%;
  height: 100%;
  .hamburger-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(20px);
    background-color: #010101ba;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    z-index: 1000;
    overflow-y: auto;

    &.open {
      transform: translateX(0%);
    }
  }

  .content-container {
    padding: 100px 20px;
    padding-bottom: 20px;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    .pooks-redirect-container {
      position: absolute;
      top: 35px;
      left: 20px;
      .top-pooks-logo {
        width: 85px;
        height: auto;
      }
    }
    .expandables-container {
      display: flex;
      width: 100%;
      flex-direction: column;
      border-top: 1px solid $color-white;
      border-bottom: 1px solid $color-white;
      .gallery-redirect-button {
        font-family: $font-inter;
        text-transform: uppercase;
        font-weight: 700;
        color: $color-white;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.015em;
        padding: 20px 0;
      }
    }
    .hamburger-footer {
      @include center;
      flex-direction: column;
      .social-buttons-container {
        @include center;
      }
    }
  }
}

.divider {
  height: 1px;
  width: 100%;
  &.MuiDivider-root {
    border-color: $color-white;
  }
}
