@import '../styles/common.scss';

.embedded-page-container {
  ::-webkit-scrollbar {
    display: none;
  };
  scrollbar-width: none;
  .iframe-container {
    @include center;
  }
}
