@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/common.scss";

.navbar-container {
  padding: 20px 80px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  .navbar-content-container {
    @include center;
    position: relative;
    .pooks-header-logo-container {
      width: 70px;
      height: auto;
      position: absolute;
      z-index: 999;
      left: 0;
      &:hover {
        cursor: pointer;
        pointer-events: all;
      }
      .pooks-header-logo {
        width: 70px;
        height: auto;
      }
    }
    .navbar-content {
      @include center;
      width: 100%;
      z-index: 100;
      flex-direction: row;
      gap: 6px;
      .gallery-button-container {
        @include center;
        padding: 2px 15px;
        border-radius: 4px;
        .gallery-redirect-button {
          font-family: $font-inter;
          color: $color-black;
          font-size: 14px;
          font-weight: 700;
          text-transform: uppercase;
        }
      }
    }
  }
}
