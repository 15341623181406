@import '../../styles/colors.scss';
@import '../../styles/fonts.scss';
@import '../../styles/common.scss';

.hamburger{
  position: fixed;
  width: 25px;
  height: 25px;
  top: 35px;
  right: 15px;
  transition-duration: 0.5s;
  z-index: 9999;
  
  .icon {
    transition-duration: 0.5s;
    position: absolute;
    height: 0px;
    width: 30px;
    top: 0px;
    background-color: $color-white;
    
    &:before{
      transition-duration: 0.5s;
      position: absolute;
      width: 20px;
      height: 3px;
      background-color: $color-black;
      content: "";
      top: 0px;

    }
    
    &:after{
      transition-duration: 0.5s;
      position: absolute;
      width: 20px;
      height: 3px;
      background-color: $color-black;
      content: "";
      top: 10px;

    }
  }
  
  &.open {
    .icon {
      transition-duration: 0.5s;
      
      &:before{
        transform: rotateZ(135deg) scaleX(1.1) translate(7px, -3px);
        background-color: $color-white;
      }

      &:after{
        transform: rotateZ(-135deg) scaleX(1.1) translate(3px, 0px);
        background-color: $color-white;
      }
    }
  }
  &:hover {
    cursor: pointer;
  }
}