// TODO: change colour scheme when established

// neutrals
$color-black: #000000;
$color-white: #ffffff;
$color-lt-grey: #D7D7D7;
$color-hover: #0606060d;

// old color schemes
$color-primary: #FF9C41;
$color-primary-opaque: #FFB580;
$color-transparent: #ffffff00;
$color-semi-transparent: #ffffff2e;
$color-opaque-white: #ffffff33;
$color-opaque-black: #00000080;
$color-grey-white: #e4e4e4;
$color-lt-grey: #93939398;

// new color schemes
$color-grey: #BFBFBF;
$color-alt-grey: #6D6D6D;
$color-dk-grey: #343434;
$color-disabled-grey: #D8D8D8;
$color-off-white: #F8EFE3;
$color-off-white-transparent: #fffbf5f7;
$color-off-white-transparent-tinted: #efe9e1cb;
$color-off-white-orange: #F0E1C9; 
$color-ordinary-blue: #7F86FB;
$color-odd-red: #D34343;
$color-blue: #2C67FF;
$color-baby-blue: #A0E5F4;
$color-brown: #A4876C;
$color-brown-light: #CCBCAE;
$color-dk-brown: #93785F;
$color-brown-orange: #B85800;

//pooks page colors
$color-pooks-bg: #2C2C2C;
$color-pooks-grey: #6A6A6A;
$color-pooks-white: #F5F3F0;
$color-pooks-border-grey: #AEAEAE;

//landing page colors
$color-landing-grey: #A0A0A0;

// new theme colors
$box-shadow-primary: #62616148;