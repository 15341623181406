@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin start {
  @include center;
  justify-content: flex-start;
}

@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
