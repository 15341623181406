@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/common.scss";

.navigation-dropdown {
  position: relative;
  max-width: 141px;
  @include center;
  justify-content: flex-start;
  border-radius: 4px;
  padding: 2px 0px;
  padding-left: 15px;
  padding-right: 9px;
}

.dropdown-header {
  cursor: pointer;
  position: relative;
  .dropdown-title {
    font-family: $font-inter;
    color: $color-black;
    font-size: 14px;
    font-weight: 700;
    padding-right: 25px;
    text-transform: uppercase;
  }

  .dropdown-icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    transition: transform 0.3s ease;
    height: 24px;
    width: 24px;
    color: $color-black;
    @include center;
    .navigation-dropdown-expand-more-icon {
      &.MuiSvgIcon-root {
        height: auto;
        width: 12px;
        color: $color-black;
        padding: auto;
      }
    }
  }

  .open {
    transform: translateY(-50%) rotate(180deg);
  }
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  border-top: none;
  opacity: 0;
  padding-top: 10px;
  .menu-container {
    padding: 5px 0;
    background-color: $color-white;
    box-shadow: 0 3px 5px $color-landing-grey;
    border: 1px solid $color-landing-grey;
    width: 220px;
    .menu-item {
      width: 100%;
      padding: 8px 20px;
      @include center;
      &:hover {
        background-color: $color-hover;
        cursor: pointer;
        .nav-item-socials-icon {
          transform: scale(1.2);
        }
      }
      .item-text {
        font-family: $font-inria-bold;
        color: $color-black;
        font-size: 16px;
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 10px;
      }
    }
    .divider-container {
      width: 100%;
      @include center;
      .divider {
        width: 80%;
        height: 1px;
        background-color: $color-disabled-grey;
      }
    }
  }
}

.navigation-dropdown:hover .dropdown-menu {
  opacity: 1;
  height: auto;
  transform: scaleY(1);
}

.nav-item-socials-icon {
  &.MuiSvgIcon-root {
    height: 21px;
    width: 21px;
    color: $color-black;
    margin: 0 10px;
    transition: transform 0.2s ease;
    margin: 0;
  }
}
